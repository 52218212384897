import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Fragment, useEffect } from 'react';
import { IconArrowNarrowRight, IconBrandCodepen } from '@tabler/icons-react';
import { Avatar, Button, Card, Col, Flex, Row, Table, Tabs, Tag } from 'antd';

import Support from './Support';
import config, { convertCurrency } from '~/configs';
import iconSource from '~/assets/image/icon_source.png';
import iconDomain from '~/assets/image/icon_domain.png';
import iconUploadImage from '~/assets/image/icon-upload.png';
import iconCreateWebsite from '~/assets/image/icon_website.svg';
import imageAvatarDefault from '~/assets/image/avatar-default.png';

const { sources, profile, billing } = config.router;

const servicesData = [
    {
        id: 1,
        title: 'Dịch vụ tạo website',
        description: 'Bảo mật, nhanh gọn, bảo hành trọn đời',
        link: sources,
        src: iconCreateWebsite,
    },
    {
        id: 2,
        title: 'Dịch vụ mã nguồn',
        description: 'Mã nguồn sẵn có dễ dàng cài đặt',
        link: sources,
        src: iconSource,
    },
    {
        id: 3,
        title: 'Đăng ký tên miền',
        description: 'Đăng ký tên miền dành riêng cho bạn',
        link: '/domains',
        src: iconDomain,
    },
    {
        id: 4,
        title: 'Upload hình ảnh lấy link',
        description: 'Upload ảnh tốc độ cao',
        link: '/upload-images',
        src: iconUploadImage,
    },
];

function Home() {
    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = 'Thegioicode.vn - Home';
    }, []);

    const columns = [
        {
            title: 'Sản phẩm',
            dataIndex: 'product',
            key: 'product',
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            key: 'price',
            render: (price) => <span className="text-success">{convertCurrency(price)}đ</span>,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <Tag color={status ? '#52c41a' : '#ff4d4f'}>{status ? 'BẬT' : 'TẮT'}</Tag>,
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'date',
            key: 'date',
            render: (date) => <span>{moment(date.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            title: 'Hết hạn',
            dataIndex: 'date',
            key: 'date',
            render: (date) => <span>{moment(date.expired_at).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
    ];

    const items = [
        {
            label: (
                <span className="box-align-center gap-2">
                    <Avatar
                        style={{ width: 20, height: 20, lineHeight: 20, fontSize: 18, background: '#096eff', padding: 1 }}
                        icon={<IconBrandCodepen />}
                    />
                    Mã nguồn (0)
                </span>
            ),
            key: '1',
            children: (
                <Fragment>
                    <Table columns={columns} dataSource={[]} pagination={false} />

                    <div className="p-2 d-flex">
                        <Link className="hover-underline box-center hover-blue font-size-15" to={sources}>
                            <span className="mr-1">Xem thêm</span>
                            <IconArrowNarrowRight />
                        </Link>
                    </div>
                </Fragment>
            ),
        },
    ];

    return (
        <Row style={{ rowGap: 16 }}>
            <Col md={18} style={{ padding: '0 8px' }}>
                <Card className="rounded-15 mb-4" style={{ minHeight: 'calc(-120px + 100vh)' }}>
                    <div className="mb-8">
                        <h2 className="font-semibold font-size-20 mb-4">Các dịch vụ đang sử dụng</h2>

                        <Tabs type="card" items={items} className="home_tabs" />
                    </div>

                    <div>
                        <h2 className="font-semibold font-size-20 mb-5">Sử dụng dịch vụ với hệ sinh thái của chúng tôi</h2>

                        <Row
                            style={{
                                marginLeft: -5,
                                marginRight: -5,
                                rowGap: 10,
                            }}
                        >
                            {servicesData.map((service) => (
                                <Col md={8} xs={24} style={{ paddingLeft: 5, paddingRight: 5 }} key={service.id}>
                                    <Link to={service.link}>
                                        <Card hoverable style={{ padding: 2 }}>
                                            <Flex className="gap-2">
                                                <Avatar
                                                    style={{
                                                        width: 50,
                                                        height: 50,
                                                        lineHeight: 50,
                                                        fontSize: 25,
                                                        background: '#096eff',
                                                    }}
                                                    src={service.src}
                                                />
                                                <div className="link-color flex-1">
                                                    <h4 className="font-bold text-primary line-height-20 mb-0 font-size-16">
                                                        {service.title}
                                                    </h4>
                                                    <p className="line-height-20 mt-2px text-subtitle">{service.description}</p>
                                                </div>
                                            </Flex>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Card>
            </Col>

            <Col md={6} style={{ padding: '0 8px' }} className="flex-1">
                <Card
                    className="rounded-15 mb-4"
                    title={
                        <h2 className="font-semibold mb-0 white-space-break">
                            <span className="font-size-18 font-semibold">Thông tin tài khoản</span>
                        </h2>
                    }
                >
                    <Flex align="center" justify="center" className="h-full flex-column">
                        <Avatar
                            src={currentUser?.avatar_url || imageAvatarDefault}
                            style={{ fontSize: 50, width: 100, height: 100, lineHeight: 100 }}
                        />
                        <h3 className="text-center font-semibold mt-2 mb-0 font-size-20">{currentUser?.full_name}</h3>
                        <h3 className="text-center mt-1 mb-0 text-subtitle font-size-16">{currentUser?.email}</h3>

                        <div className="w-full mt-6">
                            <Link to={profile}>
                                <Button block className="rounded-10 mt-4 min-height-35">
                                    Quản lý tài khoản
                                </Button>
                            </Link>
                            <Link to={billing}>
                                <Button block type="primary" className="rounded-10 mt-2 min-height-35 boxshadow-none">
                                    Nạp tiền vào tài khoản
                                </Button>
                            </Link>
                        </div>

                        <Flex justify="space-between" className="mt-8 mb-2 w-full">
                            <span className="text-sub-title font-size-15">Tài khoản chính:</span>
                            <span className="text-primary font-size-18 font-semibold">
                                {convertCurrency(currentUser?.wallet.credit_balance)}đ
                            </span>
                        </Flex>
                        <Flex justify="space-between" className="w-full">
                            <span className="text-sub-title font-size-15">Tài khoản khuyễn mãi:</span>
                            <span className="text-primary font-size-18 font-semibold">
                                {convertCurrency(currentUser?.wallet.bonus_balance)}đ
                            </span>
                        </Flex>
                    </Flex>
                </Card>

                <Support />
            </Col>
        </Row>
    );
}

export default Home;
