import config from '~/configs';
import Apis from '~/pages/Apis';
import Home from '~/layouts/Home/Home';
import Cart from '~/pages/Billing/Cart';
import Docs from '~/pages/document/Docs';
import NotFound from '~/layouts/NotFound';
import Orders from '~/pages/Billing/Orders';
import ApiKey from '~/pages/account/ApiKey';
import ForSale from '~/pages/source/ForSale';
import ApisDetail from '~/pages/Apis/Detail';
import Balance from '~/pages/Billing/Balance';
import Profile from '~/pages/account/Profile';
import General from '~/pages/account/General';
import Recharge from '~/pages/Billing/Recharge';
import Security from '~/pages/account/Security';
import Notification from '~/pages/Notification';
import Invoices from '~/pages/Billing/Invoices';
import RefProfile from '~/components/RefProfile';
import SourcesDetail from '~/pages/source/Detail';
import SourceFree from '~/pages/source/SourceFree';
import SourcePayFee from '~/pages/source/SourcePayFee';
import Login from '~/layouts/components/Account/Login';
import HistoryLogin from '~/pages/account/HistoryLogin';
import Verify from '~/layouts/components/Account/Verify';
import OrdersDetail from '~/pages/Billing/Orders/Detail';
import TwoFactor from '~/pages/account/Security/TwoFactor';
import UserNotification from '~/pages/account/Notification';
import Register from '~/layouts/components/Account/Register';
import InvoicesDetail from '~/pages/Billing/Invoices/Detail';
import ChangePassword from '~/pages/account/Security/ChangePassword';

const {
    home,
    docs,
    user,
    carts,
    login,
    verify,
    apikey,
    billing,
    profile,
    sources,
    general,
    services,
    security,
    register,
    twoFactor,
    source_free,
    notification,
    notifications,
    history_login,
    changePassword,
    source_pay_fee,
    billing_orders,
    source_for_sale,
    billing_balance,
    billing_invoices,
} = config.router;

export const privateRoutes = [
    { path: home, component: Home },
    { path: docs, component: Docs },
    { path: carts, component: Cart },
    { path: '*', component: NotFound },
    { path: services, component: Apis },
    { path: apikey, component: ApiKey },
    { path: user, component: RefProfile },
    { path: profile, component: Profile },
    { path: general, component: General },
    { path: billing, component: Recharge },
    { path: security, component: Security },
    { path: twoFactor, component: TwoFactor },
    { path: billing_orders, component: Orders },
    { path: source_free, component: SourceFree },
    { path: source_for_sale, component: ForSale },
    { path: billing_balance, component: Balance },
    { path: login, component: Login, layout: null },
    { path: billing_invoices, component: Invoices },
    { path: notifications, component: Notification },
    { path: history_login, component: HistoryLogin },
    { path: source_pay_fee, component: SourcePayFee },
    { path: verify, component: Verify, layout: null },
    { path: notification, component: UserNotification },
    { path: changePassword, component: ChangePassword },
    { path: services + '/:slug', component: ApisDetail },
    { path: register, component: Register, layout: null },
    { path: sources + '/detail/:slug', component: SourcesDetail },
    { path: billing_orders + '/:order_id', component: OrdersDetail },
    { path: billing_invoices + '/:invoice_id', component: InvoicesDetail },
];
