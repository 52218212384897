import request from '~/utils';

export const requestGetSources = async (page, category) => {
    try {
        const res = await request.get('/my/sources', {
            params: {
                page,
                category,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetSourceBySlug = async (slug) => {
    try {
        const res = await request.get(`/my/sources/${slug}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestAddSourceCart = async (slug_url) => {
    try {
        const res = await request.post('/my/sources/add-cart', { slug_url });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
