import { Fragment, useState } from 'react';
import { Badge, Button, Flex, Image, Skeleton, Tooltip } from 'antd';
import { IconCalendar, IconDownload, IconEye } from '@tabler/icons-react';

import './SourceItem.css';
import imageNotFound from '~/assets/image/image_not.jpg';
import { convertCurrency, convertTimeUnit } from '~/configs';

function SourceItem({
    href,
    target = '_self',
    code,
    title,
    image_url,
    created_at,
    views,
    purchases,
    price,
    old_price,
    btn_text,
    usage_time = null,
}) {
    const [visible, setVisible] = useState(false);

    return (
        <Badge.Ribbon text={`Mã: ${code}`}>
            <div className="source__item">
                <div className="img-slide border-none">
                    <Image
                        width="100%"
                        className="border"
                        src={image_url}
                        alt={title}
                        fallback={imageNotFound}
                        style={{ height: 180, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                        placeholder={<Skeleton active />}
                        preview={{
                            visible,
                            src: image_url,
                            onVisibleChange: (value) => {
                                setVisible(value);
                            },
                        }}
                    />

                    <Button type="primary" className="slide-button box-center" size="small" onClick={() => setVisible(true)}>
                        <IconEye size={20} />
                    </Button>
                </div>

                <div className="p-3">
                    <a href={href} target={target} rel="noreferrer" className="source__item-title">
                        <Tooltip title={title}>
                            <h3 className="font-size-19 font-bold line-height-22">{title}</h3>
                        </Tooltip>
                    </a>
                    <Flex align="center" justify="flex-start" className="mt-2 mb-2 text-subtitle source__item-meta">
                        <span className="font-size-13 box-center" title="Ngày phát hành">
                            <IconCalendar size={18} className="mr-1" />
                            {created_at}
                        </span>
                        <span className="font-size-13 box-center" title="Tổng lượt xem">
                            <IconEye size={18} className="mr-1" />
                            {views}
                        </span>
                        <span className="font-size-13 box-center" title="Tổng lượt mua">
                            <IconDownload size={18} className="mr-1" />
                            {purchases}
                        </span>
                    </Flex>
                </div>
                <div className="border-top source__item-action">
                    <Flex justify="space-between">
                        {price && old_price > 0 ? (
                            <Fragment>
                                <div className="w-full">
                                    <span className="font-size-18 font-bold text-danger mr-1">
                                        {typeof price === 'number' ? `${convertCurrency(price)}đ` : price}
                                    </span>
                                    {usage_time && convertTimeUnit(usage_time.value, usage_time.unit) !== 'Vĩnh viễn' && (
                                        <span className="font-bold text-subtitle">
                                            / {convertTimeUnit(usage_time.value, usage_time.unit)}
                                        </span>
                                    )}
                                </div>

                                <span className="text-subtitle font-size-15 text-line-through">{convertCurrency(old_price)}đ</span>
                            </Fragment>
                        ) : (
                            <span className="font-size-18 font-bold text-center w-full text-danger">Miễn phí</span>
                        )}
                    </Flex>

                    <a href={href} target={target} rel="noreferrer">
                        <Button type="primary" className="text-uppercase w-full mt-2">
                            {btn_text}
                        </Button>
                    </a>
                </div>
            </div>
        </Badge.Ribbon>
    );
}

export default SourceItem;
