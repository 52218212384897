import { Tabs } from 'antd';
import { Fragment } from 'react';

import Result from '../Result';

function Example({ data }) {
    const items = data.map((item, index) => ({
        key: String(index),
        label: <span>{item.name}</span>,
        children: <Result data={item.data} />,
    }));

    return (
        <Fragment>
            <div className="font-size-15 font-bold mt-5 mb-2">Ví dụ:</div>
            <Tabs defaultActiveKey="0" items={items} type="card" />
        </Fragment>
    );
}

export default Example;
