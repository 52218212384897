import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import config from '~/configs';

const { profile } = config.router;

function RefProfile() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(profile);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div></div>;
}

export default RefProfile;
