import request from '~/utils';

export const requestGetRecharge = async () => {
    try {
        const res = await request.get('/my/billings/recharge');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetBalances = async (page) => {
    try {
        const res = await request.get('/my/billings/balances', {
            params: {
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetOrders = async (page) => {
    try {
        const res = await request.get(`/my/billings/orders`, {
            params: {
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetOrderDetail = async (order_id) => {
    try {
        const res = await request.get(`/my/billings/orders/${order_id}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestPaymentOrder = async (order_id) => {
    try {
        const res = await request.post(`/my/billings/orders/payment/${order_id}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCancelledOrder = async (order_id) => {
    try {
        const res = await request.post(`/my/billings/orders/canceled/${order_id}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetInvoices = async (page) => {
    try {
        const res = await request.get(`/my/billings/invoices`, {
            params: {
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetInvoiceDetail = async (invoices_id) => {
    try {
        const res = await request.get(`/my/billings/invoices/${invoices_id}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestPaymentInvoice = async (order_id) => {
    try {
        const res = await request.post(`/my/billings/invoices/payment/${order_id}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
