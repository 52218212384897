import { Card, Flex } from 'antd';

function Account({ children }) {
    return (
        <Flex justify="center" align="center" style={{ height: '100vh' }} className="login">
            <Card className="login-content">{children}</Card>
        </Flex>
    );
}

export default Account;
