import hljs from 'highlight.js';
import { useEffect } from 'react';
import { Button, message } from 'antd';
import 'highlight.js/styles/a11y-light.css';
import { IconCopy } from '@tabler/icons-react';

function Result({ data }) {
    useEffect(() => {
        const originalConsoleLog = console.log;

        console.log = () => {};

        hljs.highlightAll();

        console.log = originalConsoleLog;
    }, []);

    const handleClickCopy = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                message.success('Đã sao chép vào keyboard');
            })
            .catch((err) => {
                message.error(`Lỗi sao chép: ${err}`);
            });
    };

    return (
        <div className="copy">
            <pre>
                <code className="language-javascript text-copy">{data}</code>
            </pre>

            <div className="btn-copy">
                <Button size="small" className="box-center" onClick={() => handleClickCopy(data)}>
                    <IconCopy size={18} />
                </Button>
            </div>
        </div>
    );
}

export default Result;
