import axios from 'axios';

const baseURL = 'https://thegioicode.vn/api';

export const urlUpload = `${baseURL}/images/upload`;

const request = axios.create({
    baseURL,
    withCredentials: true,
});

export default request;
