import request from '~/utils';

export const requestGetHistoryLogin = async (page) => {
    try {
        const res = await request.get('/my/accounts/history-login', {
            params: {
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateProfileUser = async (data) => {
    try {
        const res = await request.put('/my/accounts/profile', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetApikeyUser = async () => {
    try {
        const res = await request.get('/my/accounts/api-keys');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateApikeyUser = async (data, action = null) => {
    try {
        const res = await request.put('/my/accounts/api-keys/update', data, {
            params: {
                action,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetCartsUser = async () => {
    try {
        const res = await request.get('/my/accounts/carts');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestClearCartsUser = async (data) => {
    try {
        const res = await request.put('/my/accounts/carts/clear', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCreateOrderCartUser = async () => {
    try {
        const res = await request.get('/my/accounts/carts/create-order');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetNotification = async (modun, page) => {
    try {
        const res = await request.get('/my/accounts/notifications', {
            params: {
                modun,
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUnreadNotification = async (data) => {
    try {
        const res = await request.post('/my/accounts/notifications/unread', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestChangePassword = async (data) => {
    try {
        const res = await request.post('/my/accounts/password-change', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestVerifyPassword = async (data) => {
    try {
        const res = await request.post('/my/accounts/verify-password', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestSecurityEnable2Fa = async (data) => {
    try {
        const res = await request.post('/my/accounts/security/enable-2fa', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestSecurityVerify2Fa = async (data) => {
    try {
        const res = await request.post('/my/accounts/security/verify-2fa', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestSecurityTurnoff2Fa = async (data) => {
    try {
        const res = await request.post('/my/accounts/security/turnoff-2fa', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestSendCodeVerifyEmail = async () => {
    try {
        const res = await request.post('/my/accounts//send-verify-email');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
