import { Table } from 'antd';

function DataQuery({ title, data }) {
    const columnHeaders = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
    ];

    return (
        <div>
            <div className="font-size-15 font-bold mt-5 mb-1">{title}:</div>
            <Table columns={columnHeaders} dataSource={data} pagination={false} bordered />
        </div>
    );
}

export default DataQuery;
