const user = '/user';
const sources = '/sources';
const billing = '/billing';
const services = '/services';
const security = '/security';

const router = {
    user,
    billing,
    home: '/',
    docs: '/docs',
    carts: '/carts',
    login: '/login',
    sources: sources,
    verify: '/verify',
    services: services,
    register: '/register',
    affiliate: '/affiliate',
    security: user + security,
    profile: user + '/profile',
    apikey: user + '/api-keys',
    general: user + '/general',
    source_free: sources + '/free',
    notifications: '/notifications',
    billing_orders: billing + '/orders',
    notification: user + '/notification',
    source_pay_fee: sources + '/pay-fee',
    billing_balance: billing + '/balance',
    source_for_sale: sources + '/for-sale',
    history_login: user + '/history-login',
    billing_invoices: billing + '/invoices',
    changePassword: user + security + '/change-password',
    twoFactor: user + security + '/two-factor-authentication',
};

export default router;
