import { Card } from 'antd';

const data = [
    {
        id: 1,
        title: 'Liên hệ qua hotline',
        description: 'Liên hệ qua hotline để được tư vấn',
        link: 'tel:0706661234',
    },
    {
        id: 2,
        title: 'Liên hệ qua zalo',
        description: 'Liên hệ qua zalo để được tư vấn',
        link: 'https://zalo.me/0706661234',
    },
    {
        id: 3,
        title: 'Liên hệ qua telegram',
        description: 'Liên hệ qua telegram để được tư vấn',
        link: 'https://t.me/x9cmx',
    },
];

function Support() {
    return (
        <Card
            className="rounded-15 mb-4"
            title={
                <h2 className="font-semibold mb-0 white-space-break">
                    <span className="font-size-18 font-semibold">Hỗ trợ</span>
                </h2>
            }
        >
            {data.map((item) => (
                <div className="link-color" key={item.id}>
                    <a href={item.link} className="text-primary font-bold font-size-15" target="_blank" rel="noreferrer">
                        {item.title}
                    </a>
                    <p className="text-subtitle line-height-17 mt-2px mb-4">{item.description}</p>
                </div>
            ))}
        </Card>
    );
}

export default Support;
