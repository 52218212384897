import { Tabs } from 'antd';
import Result from '../Result';

function Response({ data }) {
    const items = data.map((item, index) => ({
        key: String(index),
        label: (
            <span className={item.status === 200 ? 'text-success' : 'text-danger'}>
                {item.status} - {item.title}
            </span>
        ),
        children: <Result data={JSON.stringify(item.data, null, 3)} />,
    }));

    return (
        <div>
            <div className="font-size-15 font-bold mt-5 mb-1">Response:</div>
            <Tabs defaultActiveKey="0" items={items} type="card" />
        </div>
    );
}

export default Response;
